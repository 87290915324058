<script setup lang="ts">
import type { LiquidityItem, PerformanceItem } from '@/service/modules/amm'

const { poolOverviewList, updatePool, updatePoolOverviewList } = useAmm()

const route = useRoute()
const router = useRouter()
const { isSigned } = useKey()
const { isConnected } = useUser()
const { openModal } = useModal()
const currencyDirection = ref('')
const currencyPerformance = ref('TVL')
const { symbolPrice } = useWs()
const { symbolList, tickerInfo, currencyList } = useSymbol()
const poolLiquidity = ref<LiquidityItem[]>([])
const poolPerformance = ref<PerformanceItem[]>([])

const { run: getLiquidity, isLoading: loadingLiquidity } = useHttp(vesselApi.amm.getPoolLiquidity)
const { run: getPerformance, isLoading: loadingPerformance } = useHttp(vesselApi.amm.getPoolPerformance)

const currentPoolId = computed(() => (route.params as { poolId: string }).poolId)
const currentItem = computed(() => poolOverviewList.value.find(i => i.poolId === currentPoolId.value))
const currentSymbol = computed(() => `${currentItem.value?.baseTokenName}${currentItem.value?.quoteTokenName}`)
const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbol.value))
const currentSymbolItem = computed(() => symbolList.value.find(i => i.symbol === currentSymbol.value))
const isQuote = computed(() => currencyDirection.value !== currentItem.value?.quoteTokenName)

const basePrecision = computed(() => currencyList.value.find(i => i.assetName === getCurrencyFromSymbol(currentSymbol.value)?.baseAssetName)?.depositPrecision)

const currentPrice = computed(() => {
  return symbolPrice.value?.[currentSymbol.value]?.closePrice
})

watch(currentItem, () => {
  currencyDirection.value = currentItem.value?.baseTokenName
}, {
  immediate: true,
})

function getPoolLiquidity() {
  getLiquidity(currentPoolId.value).then((res) => {
    poolLiquidity.value = res.data.ticks
  })
}
function getPoolPerformance() {
  getPerformance(currentPoolId.value).then((res) => {
    poolPerformance.value = res.data.data
  })
}

function onAddLiquidity() {
  if (!isConnected.value) {
    openModal('chooseProvider')
  }
  else if (!isSigned.value) {
    checkAndBindVesselKey()
  }
  else {
    router.push({
      path: '/pools/add',
      query: {
        symbol: `${currentItem.value?.baseTokenName}${currentItem.value?.quoteTokenName}`,
        ts: `${currentItem.value?.tickSpacing}`,
      },
    })
  }
}

onMounted(() => {
  updatePool()
  updatePoolOverviewList()
  getPoolLiquidity()
  getPoolPerformance()
})
</script>

<template>
  <div class="mx-auto h-full w-11.5 p-0.16">
    <div class="flex">
      <v-back name="Back" @click="$router.back()" />
    </div>

    <div class="mt-0.16 flex justify-between">
      <div class="flex items-center gap-x-0.08">
        <v-icon-pair :base="currentItem?.baseTokenName" :quote="currentItem?.quoteTokenName" :size="32" />
        <div class="">
          <div class="mb-0.04 text-body1b text-white2">
            {{ currentItem?.baseTokenName }}/{{ currentItem?.quoteTokenName }}
          </div>
          <VTickSpacing class="w-auto" :tick-spacing="currentItem.tickSpacing" :token="currentItem?.quoteTokenName" />
        </div>
      </div>
      <div class="flex gap-x-0.12">
        <v-button @click="onAddLiquidity">
          Add Liquidity
        </v-button>
      </div>
    </div>
    <div class="mt-0.16 flex gap-0.16">
      <div class="flex-grow-212 flex-basis-0 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
        <div>
          <div class="text-0.14 text-grey1 font-500">
            TVL
          </div>
          <div class="text-0.24 text-white2 font-600">
            ${{ formatNumberWithUnit(+currentItem?.baseTvlValue + +currentItem?.quoteTvlValue) }}
          </div>
        </div>
        <div class="mt-0.24">
          <div class="flex items-center text-0.14 text-grey1 font-500">
            <v-icon :currency="currentItem?.baseTokenName" class="mr-0.04 w-0.24" />
            {{ currentItem?.baseTokenName }}
          </div>
          <div class="text-0.24 text-white2 font-600">
            {{ formatNumberWithUnit(currentItem?.baseTvl) }}
          </div>
          <div class="text-0.12 text-grey1 text-white2 font-500">
            ${{ formatNumberWithUnit(currentItem?.baseTvlValue) }}
          </div>
        </div>
        <div class="mt-0.24">
          <div class="flex items-center text-0.14 text-grey1 font-500">
            <v-icon :currency="currentItem?.quoteTokenName" class="mr-0.04 w-0.24" />
            {{ currentItem?.quoteTokenName }}
          </div>
          <div class="text-0.24 text-white2 font-600">
            {{ formatNumberWithUnit(currentItem?.quoteTvl) }}
          </div>
          <div class="text-0.12 text-grey1 text-white2 font-500">
            ${{ formatNumberWithUnit(currentItem?.quoteTvlValue) }}
          </div>
        </div>
      </div>
      <div class="flex-grow-212 flex-basis-0 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
        <div>
          <div class="text-0.14 text-grey1 font-500">
            24h Volume
          </div>
          <div class="text-0.24 text-white2 font-600">
            ${{ formatNumberWithUnit(currentItem?.tradeVolume24h) }}
          </div>
        </div>
        <div class="mt-0.24">
          <div class="flex text-0.14 text-grey1 font-500">
            24h Fees
          </div>
          <div class="text-0.24 text-white2 font-600">
            ${{ formatNumberWithUnit(currentItem?.feeValue24h) }}
          </div>
        </div>
        <div class="mt-0.43">
          <div class="flex items-center text-0.14 text-grey1 font-500">
            Est.APR
            <n-tooltip>
              <div class="mr-0.04 w-2 text-0.12">
                Est. APR is based on the average APR over the last 24 hours’ fees and TVL. The actual APR may vary.
              </div>
              <template #trigger>
                <svg class="ml-0.04 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 13.3333C11.745 13.3333 14.1328 10.9455 14.1328 7.99998C14.1328 5.05446 11.745 2.66665 8.79948 2.66665C5.85396 2.66665 3.46615 5.05446 3.46615 7.99998C3.46615 10.9455 5.85396 13.3333 8.79948 13.3333ZM8.79948 14.6666C12.4814 14.6666 15.4661 11.6819 15.4661 7.99998C15.4661 4.31808 12.4814 1.33331 8.79948 1.33331C5.11758 1.33331 2.13281 4.31808 2.13281 7.99998C2.13281 11.6819 5.11758 14.6666 8.79948 14.6666Z" fill="#777E91" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79948 4.66666C8.43129 4.66666 8.13281 4.96513 8.13281 5.33332C8.13281 5.70151 8.43129 5.99999 8.79948 5.99999C9.16767 5.99999 9.46615 5.70151 9.46615 5.33332C9.46615 4.96513 9.16767 4.66666 8.79948 4.66666ZM8.79948 7.33332C8.43129 7.33332 8.13281 7.6318 8.13281 7.99999V10.6667C8.13281 11.0348 8.43129 11.3333 8.79948 11.3333C9.16767 11.3333 9.46615 11.0348 9.46615 10.6667V7.99999C9.46615 7.6318 9.16767 7.33332 8.79948 7.33332Z" fill="#777E91" />
                </svg>
              </template>
            </n-tooltip>
          </div>
          <div class="text-0.24 text-white2 font-600">
            {{ getRatio(currentItem?.apr) }}
          </div>
        </div>
      </div>
      <div class="relative flex-grow-654 flex-basis-0 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
        <div class="flex items-center justify-between text-0.14 text-grey1 font-500">
          Liquidity
          <v-switch v-model="currencyDirection" :options="[currentItem?.baseTokenName, currentItem?.quoteTokenName]" />
        </div>
        <div class="transform-origin-tl scale-86 text-0.12 text-grey1 font-500">
          Current Price
        </div>
        <div class="text-0.16 text-primary font-500">
          1 {{ !isQuote ? currentItem?.quoteTokenName : currentItem?.baseTokenName }} = {{ formatNumber(!isQuote ? 1 / +currentPrice : currentPrice, isQuote ? +currentTickerItem?.quoteAssetPrecision - +currentTickerItem?.baseAssetPrecision : +basePrecision) }}
          {{ !isQuote ? currentItem?.baseTokenName : currentItem?.quoteTokenName }}
        </div>
        <svg-loading v-if="loadingLiquidity" class="absolute left-1/2 top-1/2 z-51 -translate-x-1/2 -translate-y-1/2" />

        <!-- <PoolLiquidity :data="poolLiquidity" :is-quote="isQuote" :current="currentItem" :current-price="+currentPrice" :decimal="currentSymbolItem?.baseOnChainDecimal" /> -->
        <PoolLiquidity2
          :pool-id="currentPoolId"
          :data="poolLiquidity" :is-quote="isQuote" :current="currentItem" :current-price="+currentPrice" :decimal="+basePrecision"
        />
      </div>
    </div>
    <div class="relative mt-0.16 rd-0.12 bg-#23262f/30 px-0.32 py-0.12">
      <div class="mb-0.16 flex items-center justify-between text-0.14 text-grey1 font-500">
        Performance
        <v-switch v-model="currencyPerformance" :options="['TVL', 'Volume', 'Fees']" />
      </div>
      <svg-loading v-if="loadingPerformance" class="absolute left-1/2 top-1/2 z-51 -translate-x-1/2 -translate-y-1/2" />
      <PoolPerformance :p-key="currencyPerformance" :data="poolPerformance" />
    </div>
  </div>
</template>

<style scoped>

</style>
